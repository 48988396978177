import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useAudioService } from "../../Hook/AudioHook";
import GroupIcon from "@mui/icons-material/Group";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const TranscriptionTab = () => {
  const {
    transcribebtn,
    speakers,
    audioFile,
    handleSpeakersChange,
    handleTranscription,
    transcriptionText,
    setTranscriptionText,
    processing,
    elapsedTime,
  } = useAudioService();
  const theme = useTheme();

  const handleClick = async () => {
    try {
      await handleTranscription();
    } catch (error) {
      console.error("Please upload first file", error);
    }
  };

  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Box sx={{ py: 3 }}>
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <GroupIcon
            sx={{
              position: "absolute",
              right: "20px",
              top: "25px",
              color: "#afb8cd",
            }}
          ></GroupIcon>
          <TextField
            variant="outlined"
            sx={{
              minWidth: 230,
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            id="speaker"
            value={speakers}
            onChange={handleSpeakersChange}
            placeholder={t("speaker_placeholder")}
            label={t("number_of_speaker")}
            helperText={
              <span
                style={{
                  fontSize: "0.9rem",
                  color: "red",
                  margin: "10px 0",
                  display: "inline-block",
                }}
              >
                {t("transcription_error")}
              </span>
            }
          />
        </Box>
      </div>

      {/* // onClick={handleTranscription}
          // disabled={ !isTransribable }
          // color="secondary" */}

      <Button
        disabled={!audioFile || !speakers || !transcribebtn}
        // disabled={!audioFile || !speakers }
        onClick={handleClick}
        variant="contained"
        sx={{
          width: "250px",
          height: "50px",
          fontSize: "0.95rem",
          fontWeight: "bold",
          borderRadius: "100px",
          margin: "5px auto",
          display: "flex",
          backgroundColor: colors.cstmBlue[500],
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        {processing ? <CircularProgress size={24} /> : t("specch_to_text_btn")}
      </Button>

      {transcriptionText && (
        <div
          style={{
            marginTop: "20px",
            maxHeight: "550px",
            overflowY: "auto",
            border: "1px solid #402E7A",
            padding: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            lineHeight: "1.5",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h4" style={{ color: "black" }}>
            <b
              style={{
                marginBottom: "10px",
                display: "inline-block",
                color: colors.cstmPurple[500],
              }}
            >
              {" "}
              {t("transcribed_text")}
            </b>
            <br></br>
            <textarea
              value={`${elapsedTime} time taken in seconds.\n\n${transcriptionText}`}
              onChange={(e) => setTranscriptionText(e.target.value)}
              style={{
                width: "100%",
                height: "400px",
                fontFamily: "inherit",
                fontSize: "inherit",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                resize: "vertical",
              }}
            />
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default TranscriptionTab;
