import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAudioService } from "../../Hook/AudioHook";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation, Trans } from 'react-i18next';


const ContentGenerationTab = () => {
  const {
    audioModel,
    setAudioModel,
    audioContentResult,
    setAudioContentResult,
    audioContentPrompt,
    setAudioContentPrompt,
    handleAudioContentGeneration,
    isAudioContentGeneratable,
    processing,
    elapsedTime,
  } = useAudioService();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Box sx={{ py: 3 }}>
      <FormControl
        variant="outlined"
        sx={{
          minWidth: 230,
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {t('select_modal_label')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={audioModel}
          onChange={(e) => setAudioModel(e.target.value)}
          label="Select Language"
        >
          <MenuItem value={"AssemblyAI"}>AssemblyAI</MenuItem>
          <MenuItem value={"OpenAI"}>OpenAI</MenuItem>
          <MenuItem value={"ClaudeAI"}>ClaudeAI</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h5" style={{ color: "black" }}>
        <b style={{ marginBottom: "10px", display: "inline-block", color: colors.cstmPurple[500] }}> {t('promt')}</b>
        <br></br>
        <textarea
          value={audioContentPrompt}
          onChange={(e) => setAudioContentPrompt(e.target.value)}
          style={{
            width: "100%",
            height: "200px",
            fontFamily: "inherit",
            fontSize: "inherit",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "10px",
            resize: "vertical",
          }}
        />
      </Typography>
      <Button
        onClick={handleAudioContentGeneration}
        disabled={!isAudioContentGeneratable}
        color="secondary"
        variant="contained"
        sx={{
          marginTop: "5px",
          width: "250px",
          height: "50px",
          fontSize: "0.95rem",
          fontWeight: "bold",
          borderRadius: "100px",
          margin: "5px auto",
          display: "flex",
          color:"#fff",
          backgroundColor: colors.cstmBlue[500],
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          "&:hover": {
            background:colors.cstmPurple[500],
          }
        }}
      >
        {processing ? <CircularProgress size={24} /> : t('genrate_btn')}
      </Button>

      {audioContentResult && (
        <div
          style={{
            marginTop: "20px",
            maxHeight: "550px",
            overflowY: "auto",
            border: "1px solid #402E7A",
            padding: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            lineHeight: "1.5",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h4" style={{ color: "black" }}>
            <b style={{ marginBottom: "10px", display: "inline-block", color: colors.cstmPurple[500] }}>{t('genrate_content_text')}</b>
            <br></br>
            <textarea
              value={`${elapsedTime} time taken in seconds.\n\n${audioContentResult}`}
              onChange={(e) => setAudioContentResult(e.target.value)}
              style={{
                width: "100%",
                height: "400px",
                fontFamily: "inherit",
                fontSize: "inherit",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                resize: "vertical",
              }}
            />
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default ContentGenerationTab;
